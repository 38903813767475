import "../css/tailwind.css";
import "../scss/theme.scss";
import 'animate.css';
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)
window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
    Alpine.data('home', () => ({
        // messageSent: true,
        privacy: false,
        cookies: false
    }))

    Alpine.data('contactForm', () => ({
        formData: {
            nombre: '',
            apellidos: '',
            telefono: '',
            email: '',
            consulta: '',
            privacidad: false
        },
        errors: {},
        isSubmitting: false,

        // Validación personalizada
        validateField(field) {
            this.errors[field] = ''

            switch(field) {
                case 'nombre':
                    if (!this.formData.nombre.trim()) {
                        this.errors[field] = 'El nombre es requerido'
                    }
                    break
                case 'apellidos':
                    if (!this.formData.apellidos.trim()) {
                        this.errors[field] = 'Los apellidos son requeridos'
                    }
                    break
                case 'telefono':
                    if (!this.formData.telefono.trim()) {
                        this.errors[field] = 'El teléfono es requerido'
                    } else if (!/^[0-9]{9}$/.test(this.formData.telefono)) {
                        this.errors[field] = 'El teléfono debe tener 9 dígitos'
                    }
                    break
                case 'email':
                    if (!this.formData.email.trim()) {
                        this.errors[field] = 'El email es requerido'
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email)) {
                        this.errors[field] = 'El email no es válido'
                    }
                    break
                case 'consulta':
                    if (!this.formData.consulta.trim()) {
                        this.errors[field] = 'La consulta es requerida'
                    }
                    break
                case 'privacidad':
                    if (!this.formData.privacidad) {
                        this.errors[field] = 'Debes aceptar el aviso legal'
                    }
                    break
            }

            return !this.errors[field]
        },

        // Validar todo el formulario
        validateForm() {
            const fields = ['nombre', 'apellidos', 'telefono', 'email', 'consulta', 'privacidad']
            return fields.every(field => this.validateField(field))
        },

        // Manejar el envío del formulario
        async handleSubmit() {
            if (this.isSubmitting) return

            if (!this.validateForm()) {
                return
            }

            this.isSubmitting = true

            try {
                const response = await fetch('/formData/processData.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.formData)
                })

                if (!response.ok) {
                    throw new Error('Error en el envío')
                }

                // Limpiar formulario y mostrar mensaje de éxito
                this.formData = {
                    nombre: '',
                    apellidos: '',
                    telefono: '',
                    email: '',
                    consulta: '',
                    privacidad: false
                }

                // Usar el método existente para mostrar el mensaje
                this.$dispatch('show-message')

            } catch (error) {
                console.error('Error:', error)
                // Manejar el error aquí
                this.$dispatch('show-error')
            } finally {
                this.isSubmitting = false
            }
        }
    }))
});

Alpine.start()
